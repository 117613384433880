<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form
      ref="form"
      class="row"
    >
      <input-select
        v-model="form.nivel"
        class="col-12 col-md-3"
        :label="$t('modulos.feriado.form.nivel')"
        :options="opcoes.niveis"
        obrigatorio
      />
      <input-select
        v-model="form.tipo"
        class="col-12 col-md-3"
        :label="$t('modulos.feriado.form.tipo')"
        :options="opcoes.tipos"
        obrigatorio
      />
      <input-select
        :key="keyAtualiza"
        v-model="form.unidadeFederativaId"
        class="col-12 col-md-3"
        :label="$t('modulos.feriado.form.unidade_federativa')"
        :options="opcoes.unidadesFederativas"
        :disabled="form.nivel === feriadoNivel.nacional"
        :obrigatorio="form.nivel !== feriadoNivel.nacional"
        @input="buscarMunicipios"
      />
      <input-select
        :key="keyAtualiza2"
        v-model="form.municipioId"
        class="col-12 col-md-3"
        :label="$t('modulos.feriado.form.municipio')"
        :options="opcoes.municipios"
        :disabled="
          form.nivel !== feriadoNivel.municipal || !form.unidadeFederativaId
        "
        :obrigatorio="form.nivel === feriadoNivel.municipal"
      />
      <input-text
        v-model="form.dia"
        :label="$t('modulos.feriado.form.dia')"
        class="col-12 col-md-2"
        obrigatorio
        type="number"
        :min="1"
        :max="31"
      />
      <input-select
        v-model="form.mes"
        :label="$t('modulos.feriado.form.mes')"
        class="col-12 col-md-2"
        :options="opcoes.mes"
        obrigatorio
      />
      <input-text
        v-model="form.ano"
        class="col-12 col-md-2"
        type="number"
        :label="$t('modulos.feriado.form.ano')"
        :obrigatorio="form.tipo === feriadoTipo.movel"
        :disabled="form.tipo === feriadoTipo.fixo"
      />
      <input-text
        v-model="form.descricao"
        class="col-12 col-md-6"
        type="text"
        trim
        :label="$t('modulos.feriado.form.descricao')"
        :min="1"
        :max="50"
        obrigatorio
      />
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>

      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import UtilsService from '@/common/services/UtilsService.js';
import FeriadoService from '@/common/services/cadastros/FeriadoService.js';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import helpers from '@common/utils/helpers';
import { FeriadoModel } from '@common/models/cadastros/FeriadoModel.js';
import { DropdownModel } from '@common/models/geral/DropdownModel';

export default {
  // eslint-disable-next-line
  props: ['id'],
  data() {
    return {
      keyAtualiza: 1,
      keyAtualiza2: 100,
      valido: false,
      form: new FeriadoModel({}),
      opcoes: {
        unidadesFederativas: [],
        municipios: [],
        niveis: [],
        tipos: [],
        mes: helpers.MesesEnum,
      },
      nivelFeriado: [],
      tipoFeriado: [],
      feriadoTipo: {
        fixo: 'Fixo',
        movel: 'Movel',
      },
      feriadoNivel: {
        nacional: 'Nacional',
        estadual: 'Estadual',
        municipal: 'Municipal',
      },
      diasValidacao: true,
    };
  },
  computed: {
    tituloFormulario: function () {
      return this.id
        ? this.$t('modulos.feriado.titulos.editar')
        : this.$t('modulos.feriado.titulos.novo');
    },
    dias: function () {
      let days = 31;
      if (this.form.mes === 2) {
        days = 29;
      }
      if (
        this.form.mes === 4 ||
        this.form.mes === 6 ||
        this.form.mes === 9 ||
        this.form.mes === 11
      ) {
        days = 30;
      }
      return [...Array(days + 1).keys()].filter((e) => e);
    },
  },
  watch: {
    'form.nivel'() {
      if (this.form.nivel === this.feriadoNivel.nacional) {
        this.form.unidadeFederativaId = null;
        this.form.municipioId = null;
      }

      this.keyAtualiza++;
      this.keyAtualiza2++;
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this,'Feriado', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'Feriado', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);

    this.buscarUnidadesFederativas();
    this.buscarTipoFeriado();
    this.buscarNivelFeriado();
  },
  methods: {
    validacaoDias() {
      this.diasValidacao = true;
      if (this.form.mes === 2) {
        if (this.form.dia > 29) {
          this.toastAlerta(
            this.$t('modulos.feriado.validacao_dias.validacao_dias_29')
          );
          this.diasValidacao = false;
        }
      } else if (
        this.form.mes === 4 ||
        this.form.mes === 6 ||
        this.form.mes === 9 ||
        this.form.mes === 11
      ) {
        if (this.form.dia > 30) {
          this.toastAlerta(
            this.$t('modulos.feriado.validacao_dias.validacao_dias_30')
          );
          this.diasValidacao = false;
        }
      } else {
        if (this.form.dia > 31) {
          this.toastAlerta(
            this.$t('modulos.feriado.validacao_dias.validacao_dias_31')
          );
          this.diasValidacao = false;
        }
      }

      return this.diasValidacao;
    },
    confirmacaoSalvar: function () {
      this.keyAtualiza++;
      this.validacaoFormulario();
      if (!this.validacaoDias()) return;
      if (!this.valido) return;

      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      FeriadoService.salvar(this.form)
        .then(() => {
          this.toastSucesso(this.$t('modulos.feriado.cadastro_sucesso'));

          this.$router.push({ name: 'feriado' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    validacaoFormulario() {
      if (this.$refs.form) {
        const {
          nivel,
          tipo,
          dia,
          mes,
          ano,
          unidadeFederativaId,
          municipioId,
          descricao,
        } = this.form;

        let valido = false;

        if (nivel && tipo && dia && mes) {
          valido = true;

          if (nivel === this.feriadoNivel.estadual && !unidadeFederativaId) {
            valido = false;
          }

          if (
            nivel === this.feriadoNivel.municipal &&
            (!unidadeFederativaId || !municipioId)
          ) {
            valido = false;
          }

          if (tipo === this.feriadoTipo.movel && (ano === undefined || !ano)) {
            valido = false;
          }

          if (!descricao || descricao.trim() === '' || descricao.length > 200) {
            valido = false;
          }
        }

        this.valido = valido;

        if (this.$refs.form) this.valido = this.$refs.form.validate();
      }
    },
    buscar: function (id) {
      FeriadoService.buscar(id).then((response) => {
        this.form = new FeriadoModel(response.data);

        switch (this.form.nivel) {
          case 1:
            this.form.nivel = 'Nacional';
            break;
          case 2:
            this.form.nivel = 'Estadual';
            break;
          case 3:
            this.form.nivel = 'Municipal';
            break;
          case 4:
            this.form.nivel = 'Nacional';
            break;
        }
        switch (this.form.tipo) {
          case 1:
            this.form.tipo = 'Fixo';
            break;
          case 2:
            this.form.tipo = 'Movel';
            break;
        }

        if (this.form.nivel == 'Municipal') {
          this.buscarMunicipios();
        }
      });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'feriado' });
      });
    },
    buscarUnidadesFederativas: function () {
      UtilsService.buscarUnidadeFederativa().then((response) => {
        this.opcoes.unidadesFederativas = new DropdownModel(response.data);
      });
    },
    buscarMunicipios: function () {
      if (this.form.unidadeFederativaId) {
        UtilsService.buscarTodosMunicipios(this.form.unidadeFederativaId).then(
          (response) => {
            this.opcoes.municipios = new DropdownModel(response.data);
          }
        );
      }
    },
    buscarTipoFeriado: function () {
      EnumeradorService.buscar('EnumTipoFeriado').then((res) => {
        this.opcoes.tipos = res;
      });
    },
    buscarNivelFeriado: function () {
      EnumeradorService.buscar('EnumNivelFeriado').then((res) => {
        this.opcoes.niveis = res;
        this.opcoes.niveis = this.opcoes.niveis.filter((item) => {
          return item.value !== 'Federal';
        });
      });
    },
  },
};
</script>
