export class FeriadoModel {
  constructor({ id, nivel = null, tipo = null, municipioId = null, unidadeFederativaId = null, dia, mes, ano, descricao }) {
    this.id = id;
    this.nivel = nivel;
    this.tipo = tipo;
    this.municipioId = municipioId;
    this.unidadeFederativaId = unidadeFederativaId;
    this.dia = dia;
    this.mes = mes;
    this.ano = ano;
    this.descricao = descricao;
  }
}
